import { Popover, Transition } from '@headlessui/react'
import { Info } from 'react-feather'
import React, { EventHandler, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

interface InformationIconProps {
  className?: string
  description?: string
  onClick?: EventHandler<React.MouseEvent>
}
export default function InformationIcon ({ className, description, onClick, children }: PropsWithChildren<InformationIconProps>): JSX.Element | undefined {
  if (description === '' && onClick === undefined) {
    return undefined
  }

  if (onClick !== undefined) {
    return <Info size={16} className='text-brand-primary cursor-pointer' onClick={onClick} tabIndex={0} role='button' />
  }

  if (description !== undefined && description !== '') {
    return (
      <Popover className='inline-block ml-2 relative'>
        <Popover.Button className={twMerge(classNames('inline-block outline-offset-4', className))}>
          {children ?? <Info size={16} className='text-brand-primary' />}
        </Popover.Button>

        <Transition
          enter='transition duration-100 ease-out'
          enterFrom='transform scale-95 opacity-0'
          enterTo='transform scale-100 opacity-100'
          leave='transition duration-75 ease-out'
          leaveFrom='transform scale-100 opacity-100'
          leaveTo='transform scale-95 opacity-0'
        >
          <Popover.Panel className='absolute bg-brand-light p-2 rounded border border-brand-primary w-64 left-[50%] translate-x-[-50%] bottom-8
        after:bg-brand-light after:w-4 after:h-4 after:absolute after:-bottom-2 after:left-[50%] after:translate-x-[-50%] after:border-b after:border-r after:rotate-45 after:border-brand-primary
        '
          >{description}
          </Popover.Panel>
        </Transition>
      </Popover>
    )
  }
}
