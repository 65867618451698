import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Calendar from '../components/calendar'
import dayjs from '../libs/dayjs'
import MonthSelect from '../components/month-select'
import {
  useDeparturePrices,
  useDepartures,
  useDepartureStates,
  useMinMaxDate,
  usePreloadDepartures, useTwoMonthDepartures
} from '../hooks/departures'
import classNames from 'classnames'
import { TourContext } from '../contexts/tour'
import BookingProgress from '../components/booking-progress'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import NumberSelect from '../components/number-select'
import CalendarLegend from '../components/calendar-legend'
import TwoColumnLayout from '../layouts/columns'
import { usePreloadQuery } from '../hooks/preload'
import { ShipTranslationsQuery } from '../queries/translations'
import Sidebar from '../components/sidebar'
import DepartureProvider from '../contexts/departure'
import { WithError } from '../components/with-error'
import DoubleCalendar from '../components/double-calendar'
import { useFrameDetector } from '../hooks/frame-detector'
import { useResizeObserver } from 'usehooks-ts'
import { useBrandBasePath } from '../hooks/brand'
import { filterDepartures } from '../utils/departures'
import { z } from 'zod'

const IS_DEVELOPMENT_STAGE = ['dev', 'acc'].includes(import.meta.env.VITE_STAGE)

export default function SelectDeparture (): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const frame = useFrameDetector()
  const { tour } = useContext(TourContext)
  const [searchParams] = useSearchParams()
  const initialDate = searchParams.has('date') ? dayjs(searchParams.get('date')) : undefined
  const [showError, setShowError] = useState(false)
  const [month, setMonth] = useState(() => {
    if (initialDate != null) {
      return initialDate
    }
    if (tour.first_available !== null) {
      return dayjs(tour.first_available)
    }
    const tomorrow = dayjs().add(1, 'day')
    const firstDeparture = dayjs(tour.first_departure)
    if (tomorrow.isBefore(firstDeparture)) {
      return firstDeparture
    }
    return tomorrow
  })
  const [partySize, setPartySize] = useState(() => {
    return z.coerce.number().nullable().parse(searchParams.get('party')) ?? 2
  })
  const [selectedDate, setSelectedDate] = useState(() => {
    if (initialDate !== undefined) {
      return initialDate
    }
    const date = sessionStorage.getItem('date')
    if (date !== null) {
      return dayjs(date)
    }
    return undefined
  })

  const showBack = useMemo(() => IS_DEVELOPMENT_STAGE && !frame, [frame])

  const [
    { data: departuresFirstMonth, isLoading: isLoadingFirstMonth },
    { data: departuresSecondMonth, isLoading: isLoadingSecondMonth }
  ] = useTwoMonthDepartures(tour.id, month)
  const { data: departuresInSelectedMonth, isLoading: isLoadingSelectedMonth } = useDepartures(tour.id, selectedDate ?? month)
  const isLoading = isLoadingFirstMonth || isLoadingSecondMonth || isLoadingSelectedMonth
  const departures = useMemo(() => {
    // Departures in different months will not be the same
    const regularDepartures = (departuresFirstMonth ?? []).concat(departuresSecondMonth ?? [])
    const departureIds = new Set(regularDepartures.map(d => d.id))
    // But departures in the selected month might be
    const extraDepartures = (departuresInSelectedMonth ?? []).filter(d => !departureIds.has(d.id))
    return regularDepartures.concat(extraDepartures)
  },
  [departuresFirstMonth, departuresInSelectedMonth, departuresSecondMonth])
  const { data: prices } = useDeparturePrices(tour.id)
  const basePath = useBrandBasePath()

  const [minimumDate, maximumDate] = useMinMaxDate(tour)

  useEffect(() => {
    if (minimumDate.isAfter(month, 'month')) {
      setMonth(minimumDate)
    }
  }, [minimumDate, month])

  const months = useMemo(() => {
    const dates = []
    for (let current = minimumDate.clone().startOf('month'); !current.isAfter(maximumDate, 'month'); current = current.add(1, 'month')) {
      const year = current.year()
      const month = current.month() + 1
      const price = prices === undefined ? undefined : prices.find(p => p.year === year && p.month === month)
      dates.push({
        date: current,
        price
      })
    }
    return dates
  }, [minimumDate, maximumDate, prices])

  const changeMonth = useCallback((newMonth: dayjs.Dayjs) => {
    if (newMonth.isAfter(maximumDate, 'month') || newMonth.isBefore(minimumDate, 'month')) {
      return
    }
    setMonth(newMonth)
  }, [minimumDate, maximumDate])

  const handleNextMonth = useCallback(() => {
    const newMonth = month.add(1, 'month')
    if (newMonth.isAfter(maximumDate, 'month')) {
      return
    }
    setMonth(newMonth)
  }, [maximumDate, month])

  const handlePreviousMonth = useCallback(() => {
    const newMonth = month.subtract(1, 'month')
    if (newMonth.isBefore(minimumDate, 'month')) {
      return
    }
    setMonth(newMonth)
  }, [minimumDate, month])

  const departureStates = useDepartureStates(departures, minimumDate, partySize)

  const departuresOnSelectedDate = useMemo(() => {
    if (departures === undefined) {
      return []
    }
    const selected = selectedDate?.format('YYYY-MM-DD')
    return filterDepartures(departures, selected ?? '', partySize)
  }, [departures, partySize, selectedDate])

  const selectionRange = useMemo(() => {
    if (selectedDate === undefined) {
      return undefined
    }
    const duration = Math.max(...departuresOnSelectedDate.map(d => d.duration))
    const start = selectedDate
    const end = start.add(duration, 'day')
    return [start, end] as const
  }, [departuresOnSelectedDate, selectedDate])

  const handleNext = useCallback(() => {
    if (selectedDate === undefined || departuresOnSelectedDate.length === 0) {
      setShowError(true)
      return
    }
    const selected = selectedDate.format('YYYY-MM-DD')
    sessionStorage.setItem('date', selected)
    navigate(`../tours/${tour.id}/date/${selected}?party=${partySize}`)
  }, [departuresOnSelectedDate.length, navigate, partySize, selectedDate, tour.id])

  const handleBack = useCallback(() => {
    if (import.meta.env.DEV) {
      navigate(`${basePath}/`)
    } else {
      navigate(`${basePath}/tours/${tour.id}`)
    }
  }, [basePath, navigate, tour.id])

  const handleSelectDate = useCallback((newDate: dayjs.Dayjs) => {
    if (!dayjs().isBefore(newDate, 'day')) {
      return
    }

    const selected = newDate.format('YYYY-MM-DD')
    if (!departureStates.has(selected) || departureStates.get(selected)?.type === 'unavailable') {
      return
    }

    // Deselect if the same date is selected again
    setSelectedDate(oldValue => (oldValue?.isSame(newDate, 'day') ?? false) ? undefined : newDate)
  }, [departureStates])

  useEffect(() => {
    if (departuresOnSelectedDate.length === 0 && !isLoading) {
      setSelectedDate(undefined)
    }
  }, [departuresOnSelectedDate.length, isLoading])

  usePreloadDepartures(tour?.id, tour?.first_available ?? undefined)
  usePreloadQuery(ShipTranslationsQuery())
  usePreloadDepartures(tour?.id, month.add(2, 'month'))
  usePreloadDepartures(tour?.id, month.subtract(1, 'month'))

  const calendarsRef = useRef(null)
  const { height: calendarHeight } = useResizeObserver({
    ref: calendarsRef
  })

  return (
    <>
      <BookingProgress currentStep={1} />
      <TwoColumnLayout className='frame:grid-cols-[2fr_1fr]'>
        <section>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-8'>
            <div>
              <MonthSelect months={months} value={month} onMonthChange={changeMonth} />
            </div>
            <div>
              <NumberSelect min={1} max={20} value={partySize} onChange={setPartySize} translation='people_count' label={t('passenger', 'Passengers')} />
            </div>
          </div>
          <div className='@container group mt-8'>
            <div className='peer md:flex gap-8 mb-4 items-center justify-between'>
              <h2 className='text-brand-primary font-medium max-md:mb-4'>{t('prices_and_availability', 'Prices & Availability')}</h2>
            </div>
            <div className={classNames('calendars mt-4', { 'opacity-50': isLoading })} ref={calendarsRef}>
              <WithError className='rounded block' error={showError ? t('select_date', 'Please select a date') : null} clearError={() => setShowError(false)}>
                <DoubleCalendar className='double hidden @dc:grid bg-brand-well' selectionRange={selectionRange} value={selectedDate} onChange={handleSelectDate} departureStates={departureStates} min={minimumDate} max={maximumDate} month={month} onNextMonth={handleNextMonth} onPreviousMonth={handlePreviousMonth} />
                <div className='single grid @dc:hidden bg-brand-light @xl:bg-brand-well'>
                  <Calendar selectionRange={selectionRange} value={selectedDate} onChange={handleSelectDate} departureStates={departureStates} min={minimumDate} max={maximumDate} month={month} onNextMonth={handleNextMonth} onPreviousMonth={handlePreviousMonth} />
                </div>
              </WithError>
            </div>
            <CalendarLegend className='legend' key={`${String(calendarHeight ?? 0)}`} />
          </div>
        </section>
        {departuresOnSelectedDate.length === 1
          ? (
            <DepartureProvider departure={departuresOnSelectedDate[0]}>
              <Sidebar
                bookingSummaryProps={{ date: selectedDate }}
                onBack={showBack ? handleBack : undefined}
                onNext={handleNext}
              />
            </DepartureProvider>
            )
          : (
            <Sidebar
              bookingSummaryProps={{ date: selectedDate }}
              onBack={showBack ? handleBack : undefined}
              onNext={handleNext}
            />
            )}
      </TwoColumnLayout>
    </>
  )
}
