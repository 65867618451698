import {
  ChangeEvent,
  ComponentProps,
  JSX,
  JSXElementConstructor, PropsWithChildren, useCallback,
  useMemo, useRef,
  useState
} from 'react'
import { ErrorMessage, FieldProps } from 'formik'
import { twMerge } from 'tailwind-merge'
import { registerLocale, getNames } from 'i18n-iso-countries'
import countriesEN from 'i18n-iso-countries/langs/en.json'
import countriesDE from 'i18n-iso-countries/langs/de.json'
import countriesNL from 'i18n-iso-countries/langs/nl.json'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { STATES } from '../libs/states'
import dayjs from '../libs/dayjs'

registerLocale(countriesEN)
registerLocale(countriesDE)
registerLocale(countriesNL)

interface DefaultInputProps {
  label: string
  id: string
  className?: string
}

type InputProps<T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>> =
  FieldProps
  & DefaultInputProps
  & ComponentProps<T>
type SelectOption = [string, string]
type SelectProps = InputProps<'select'> & {
  options?: SelectOption[]
}

const inputClasses = {
  base: 'block border-none bg-white rounded p-2 caret-brand-primary w-full focus:ring-brand-primary text-base',
  input: 'h-10',
  select: 'h-10',
  textarea: ''
}

function Label ({ children, htmlFor, className }: PropsWithChildren<{
  htmlFor: string
  className?: string
}>): JSX.Element {
  return <label htmlFor={htmlFor} className={twMerge(classNames('block mb-3 opacity-50 font-medium text-sm', className))}>{children}</label>
}

export function TextLikeInput ({
  field,
  form,
  label,
  id,
  className,
  type = 'text',
  ...props
}: InputProps<'input'>): JSX.Element {
  const meta = form.getFieldMeta(field.name)
  return (
    <div className={classNames(className, { 'has-error': meta.touched && meta.error !== undefined })}>
      <Label htmlFor={id}>{label}</Label>
      <input
        className={classNames(inputClasses.base, inputClasses.input)}
        id={id} type={type} {...field} {...props}
      />
      <ErrorMessage
        name={field.name} component='span'
        className='text-red-500 text-sm'
      />
    </div>
  )
}

export function TextAreaInput ({
  field,
  form,
  label,
  id,
  className,
  ...props
}: InputProps<'textarea'>): JSX.Element {
  const meta = form.getFieldMeta(field.name)
  return (
    <div className={twMerge(classNames('flex flex-col', className, { 'has-error': meta.touched && meta.error !== undefined }))}>
      <Label htmlFor={id}>{label}</Label>
      <textarea
        className={classNames(inputClasses.base, inputClasses.textarea, 'flex-1')}
        id={id} {...field} {...props}
      />
      <ErrorMessage
        name={field.name} component='span'
        className='text-red-500 text-sm'
      />
    </div>
  )
}

export function SelectInput ({
  field,
  form,
  label,
  id,
  className,
  options,
  ...props
}: SelectProps): JSX.Element {
  const meta = form.getFieldMeta(field.name)
  return (
    <div className={classNames(className, { 'has-error': meta.touched && meta.error !== undefined })}>
      <Label htmlFor={id}>{label}</Label>
      <select
        className={classNames(inputClasses.base, inputClasses.select)}
        id={id} {...field} {...props}
      >
        {options?.map(([value, label]) => (
          <option key={value} value={value}>{label}</option>))}
        {props.children}
      </select>
      <ErrorMessage
        name={field.name} component='span'
        className='text-red-500 text-sm'
      />
    </div>
  )
}

const MOVE_TO_TOP: Record<'nl' | 'en' | 'de', string[]> = {
  nl: ['NL', 'BE'],
  en: ['US', 'CA', 'AU', 'GB', 'SE', 'FI'],
  de: ['DE', 'AT', 'CH']
}

const supportsHrInSelect = (function () {
  const s = document.createElement('select')
  s.innerHTML = '<hr>'
  return s.childElementCount === 1 && (navigator.userAgent.match(/iPhone|iPad/) == null)
})()

export function CountrySelect (props: Omit<SelectProps, 'options'>): JSX.Element {
  const { i18n } = useTranslation()
  const { topList, list } = useMemo(() => {
    const lang: 'en' | 'de' | 'nl' = (i18n.resolvedLanguage ?? 'en') as ('en' | 'de' | 'nl')
    const topCountries = MOVE_TO_TOP[lang]
    const countries = getNames(lang)
    const topList = topCountries.map(code => [code, countries[code]] satisfies [string, string])
    const list = Object.entries(countries)
    list.sort((a, b) => a[1].localeCompare(b[1], lang))
    return { topList, list }
  }, [i18n.resolvedLanguage])

  // Reset state when changing country
  const onChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    props.field.onChange(e)
    void props.form.setFieldValue('main.state', '', false).then(() => {
      void props.form.setFieldTouched('main.state', false, false)
    })
  }, [props.field, props.form])

  return (
    <SelectInput {...props} onChange={onChange}>
      <option value='' />
      {topList.map(([code, name]) => <option key={code} value={code}>{name}</option>)}
      {supportsHrInSelect ? <hr /> : <option disabled>&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;</option>}
      {list.map(([code, name]) => <option key={code} value={code}>{name}</option>)}
    </SelectInput>
  )
}

export function StateSelect ({ country, ...props }: Omit<SelectProps, 'options'> & { country: string }): JSX.Element | undefined {
  if (country !== 'US' && country !== 'CA') {
    return undefined
  }

  const states = STATES[country].toSorted((a, b) => a.name.localeCompare(b.name))

  return (
    <SelectInput {...props}>
      <option value='' />
      {states.map(state => <option key={state.iso} value={state.iso}>{state.name} ({state.iso})</option>)}
    </SelectInput>
  )
}

function splitDate (isoDate: string): [string, string, string] {
  const match = String(isoDate ?? '').match(/^(\d{4})-(\d{2})-(\d{2})$/)
  if (match === null) {
    return ['', '', '']
  }
  return [match[1], match[2], match[3]]
}

function padDateNumber (num: number, digits: number): string {
  return Math.abs(num).toString(10).padStart(digits, '0').substring(0, digits)
}

function combineDate (year: number, month: number, day: number): string {
  return `${padDateNumber(year, 4)}-${padDateNumber(month, 2)}-${padDateNumber(day, 2)}`
}

function tryParseDate (date: string, locale: string): Date | null {
  const d = dayjs(date, [
    'YYYY-MM-DD',
    'YYYY-M-D',
    'YYYY.MM.DD',
    'YYYY.M.D',
    'YYYY/MM/DD',
    'YYYY/M/D',
    'DD-MM-YYYY',
    'D-M-YYYY',
    'DD.MM.YYYY',
    'D.M.YYYY',
    'DD/MM/YYYY',
    'D/M/YYYY',
    'MM/DD/YYYY',
    'M/D/YYYY'
  ], locale, true)
  if (d.isValid()) {
    return d.toDate()
  }
  return null
}

function focusAndMoveCursorToEnd (input: HTMLInputElement | undefined | null): void {
  if (input == null) {
    return
  }
  // input[type="number"] does not support setSelectionRange,
  // so we perform this little trick to reset the cursor position
  const original = input.value
  input.value = ''
  input.value = original
  input.focus()
}

export function DateInput ({
  field,
  form,
  label,
  id,
  className,
  type = 'text',
  ...props
}: InputProps<'input'>): JSX.Element {
  const meta = form.getFieldMeta(field.name)

  const dayRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  const valueParts = splitDate(field.value)
  const [parsedValueYear, parsedValueMonth, parsedValueDay] = valueParts.map(part => part === '' ? null : parseInt(part, 10)).map(part => Number.isNaN(part) ? null : part)

  const [day, setDay] = useState(parsedValueDay?.toString() ?? '')
  const [month, setMonth] = useState(parsedValueMonth?.toString() ?? '')
  const [year, setYear] = useState(parsedValueYear?.toString() ?? '')

  const { t, i18n } = useTranslation()

  const isThisDateInput = useCallback((input: unknown) => {
    return input === dayRef.current || input === monthRef.current || input === yearRef.current
  }, [dayRef, monthRef, yearRef])

  const parsedDay = parseInt(day, 10)
  const parsedMonth = parseInt(month, 10)
  const parsedYear = parseInt(year, 10)

  const hasFocus = isThisDateInput(document.activeElement)
  const valueDay = (valueParts[2] === '' || hasFocus) ? day : padDateNumber(parsedValueDay ?? parsedDay, 2)
  const valueMonth = (valueParts[1] === '' || hasFocus) ? month : padDateNumber(parsedValueMonth ?? parsedMonth, 2)
  const valueYear = (valueParts[0] === '' || hasFocus) ? year : String(parsedValueYear ?? parsedYear)

  const updateValue = (day: number, month: number, year: number): void => {
    if (Number.isNaN(day) || Number.isNaN(month) || Number.isNaN(year)) {
      if (field.value !== '') {
        void form.setFieldValue(field.name, '')
      }
    } else {
      const newDate = combineDate(year, month, day)
      void form.setFieldValue(field.name, newDate)
    }
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (isThisDateInput(e.relatedTarget)) {
      // Ignore jumps between fields
      return
    }
    field.onBlur(e)
  }

  const blurDay = (e: React.FocusEvent<HTMLInputElement>): void => {
    setDay((val) => {
      if (val.length === 1) {
        return '0' + val
      }
      return val
    })
    onBlur(e)
  }

  const blurMonth = (e: React.FocusEvent<HTMLInputElement>): void => {
    setMonth((val) => {
      if (val.length === 1) {
        return '0' + val
      }
      return val
    })
    onBlur(e)
  }

  const updateDay = (newDay: string): void => {
    if (newDay.length > 2 && newDay.substring(0, 1) === '0') {
      newDay = newDay.substring(1)
    }
    setDay(newDay)
    updateValue(parseInt(newDay, 10), parsedMonth, parsedYear)
    if (newDay.length === 2) {
      focusAndMoveCursorToEnd(monthRef.current)
    }
  }

  const changeDay = (e: React.ChangeEvent<HTMLInputElement>): void => {
    updateDay(e.target.value)
  }

  const updateMonth = (newMonth: string): void => {
    if (newMonth.length > 2 && newMonth.substring(0, 1) === '0') {
      newMonth = newMonth.substring(1)
    }
    setMonth(newMonth)
    updateValue(parsedDay, parseInt(newMonth, 10), parsedYear)
    if (newMonth.length === 2) {
      focusAndMoveCursorToEnd(yearRef.current)
    }
  }

  const updateYear = (newYear: string): void => {
    setYear(newYear)
    updateValue(parsedDay, parsedMonth, parseInt(newYear, 10))
  }

  const pasteDay = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault()
    const text = e.clipboardData.getData('text')
    const date = tryParseDate(text.trim(), i18n.resolvedLanguage ?? 'en')
    if (date != null) {
      setDay(date.getDate().toString())
      setMonth((date.getMonth() + 1).toString())
      setYear(date.getFullYear().toString())
      updateValue(date.getDate(), date.getMonth() + 1, date.getFullYear())
    }
  }

  const dayKey = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === '-' || e.key === '.' || e.key === '/') {
      e.preventDefault()
      if (e.currentTarget.value.length > 0) {
        focusAndMoveCursorToEnd(monthRef.current)
      }
    }
  }

  const monthKey = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === '-' || e.key === '.' || e.key === '/') {
      e.preventDefault()
      if (e.currentTarget.value.length > 0) {
        focusAndMoveCursorToEnd(yearRef.current)
      }
      return
    }
    if (e.currentTarget.value === '' && e.key === 'Backspace') {
      focusAndMoveCursorToEnd(dayRef.current)
    }
  }

  const yearKey = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === '-' || e.key === '.' || e.key === '/') {
      e.preventDefault()
      return
    }
    if (e.currentTarget.value === '' && e.key === 'Backspace') {
      focusAndMoveCursorToEnd(monthRef.current)
    }
  }

  const subType = 'number'

  return (
    <div className={classNames(className, { 'has-error': meta.touched && meta.error !== undefined })}>
      <Label htmlFor={id}>{label}</Label>
      <div className='flex gap-2'>
        <input
          ref={dayRef}
          className={twMerge(classNames(inputClasses.base, inputClasses.input, 'flex-1 text-center'))}
          id={id + '-day'} type={subType} {...field} {...props}
          autoComplete='bday-day' inputMode='numeric'
          placeholder={t('field:date_day_placeholder', 'DD')}
          min={1} max={31} value={valueDay}
          onChange={changeDay} onBlur={blurDay}
          onPaste={pasteDay}
          onKeyDown={dayKey}
        />
        <input
          ref={monthRef}
          className={twMerge(classNames(inputClasses.base, inputClasses.input, 'flex-1 text-center'))}
          id={id + '-month'} type={subType} {...field} {...props}
          autoComplete='bday-month' inputMode='numeric'
          placeholder={t('field:date_month_placeholder', 'MM')}
          min={1} max={12} value={valueMonth}
          onChange={(e) => updateMonth(e.target.value)} onBlur={blurMonth}
          onKeyDown={monthKey}
        />
        <input
          ref={yearRef}
          className={twMerge(classNames(inputClasses.base, inputClasses.input, 'flex-2 text-center'))}
          id={id + '-year'} type={subType} {...field} {...props}
          autoComplete='bday-year' inputMode='numeric'
          placeholder={t('field:date_year_placeholder', 'YYYY')}
          min={1900} max={(new Date()).getFullYear()} value={valueYear}
          onChange={(e) => updateYear(e.target.value)} onBlur={onBlur}
          onKeyDown={yearKey}
        />
      </div>
      <ErrorMessage
        name={field.name} component='span'
        className='text-red-500 text-sm'
      />
    </div>
  )
}
