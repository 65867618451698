import { PropsWithChildren, RefObject } from 'react'
import classNames from 'classnames'

export function Tooltip ({ anchor = 'top', className, childRef, children }: PropsWithChildren<{
  anchor?: 'top' | 'bottom'
  className?: string
  childRef?: RefObject<HTMLDivElement>
}>): JSX.Element {
  return (
    <div className={classNames('absolute left-[50%] -translate-x-1/2 z-10', {
      'bottom-full': anchor === 'top',
      'top-full': anchor === 'bottom'
    }, className)}
    >
      <div
        className={classNames('block w-4 h-4 bg-brand-primary absolute left-1/2 -translate-x-1/2 rotate-45 -z-10', {
          'top-2': anchor === 'bottom',
          'bottom-2': anchor === 'top'
        })}
      />
      <div
        className={classNames('my-4 py-2 px-4 rounded text-white bg-brand-primary')}
        ref={childRef}
      >{children}
      </div>
    </div>
  )
}
