import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { PropsWithChildren } from 'react'
import classNames from 'classnames'
import InformationIcon from './information-icon'

function LegendItem ({ description, className, children }: PropsWithChildren<{ className?: string, description?: string }>): JSX.Element {
  if (description != null && description !== '') {
    return <InformationIcon description={description} className={classNames('item inline-flex items-center gap-2', className)}>{children}</InformationIcon>
  }
  return (
    <div className={twMerge(classNames('item inline-flex items-center gap-2', className))}>
      {children}
    </div>
  )
}

export default function CalendarLegend ({ className }: { className?: string }): JSX.Element {
  const { t } = useTranslation()
  return (
    <div className={twMerge('inline-flex items-center mt-8 mb-4 text-sm gap-8 flex-wrap', className)}>
      <LegendItem className='available hidden group-has-[.single_.available]:inline-flex @dc:group-has-[.double_.available]:inline-flex'>
        <div className='inline-block w-4 h-4 rounded-full bg-brand-available' />
        {t('available', 'Available')}
      </LegendItem>
      <LegendItem className='unavailable hidden group-has-[.single_.unavailable]:inline-flex @dc:group-has-[.double_.unavailable]:inline-flex'>
        <div
          className='inline-block w-4 h-4 rounded-full bg-brand-unavailable opacity-60'
        />
        {t('unavailable', 'Unavailable')}
      </LegendItem>
      <LegendItem className='on-request hidden group-has-[.single_.on-request]:inline-flex @dc:group-has-[.double_.on-request]:inline-flex' description={t('on_request_tooltip', 'Tooltip about on-request')}>
        <div
          className='inline-block w-4 h-4 rounded-full border-brand-primary border-dashed border'
        />
        <span className='decoration-dotted underline [@media(min-resolution:2x)]:decoration-2'>{t('on_request', 'On request')}</span>
      </LegendItem>
      <LegendItem className='discounted hidden group-has-[.single_.discounted]:inline-flex @dc:group-has-[.double_.discounted]:inline-flex' description={t('discount_tooltip', 'Tooltip about discount')}>
        <div className='inline-block w-4 h-4 rounded-full bg-brand-discount' />
        <span className='decoration-dotted underline [@media(min-resolution:2x)]:decoration-2'>{t('discount', 'Discount')}</span>
      </LegendItem>
    </div>
  )
}
