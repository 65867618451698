import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/nl'
import LocaleData from 'dayjs/plugin/localeData'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(LocaleData)
dayjs.extend(LocalizedFormat)
dayjs.extend(CustomParseFormat)

export default dayjs
