import Service from './base'
import { z } from 'zod'

const PaymentSchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  amount: z.coerce.number(),
  status: z.string(),
  type: z.string(),
  booking_number: z.string().nullable(),
  full_name: z.string(),
  email: z.string(),
  market: z.enum(['default', 'north_america']),
  exchange_rate: z.coerce.number()
})

export type Payment = z.infer<typeof PaymentSchema>

const PaymentIntentSchema = z.object({
  client_secret: z.string()
})

export type PaymentIntent = z.infer<typeof PaymentIntentSchema>

export class PaymentService extends Service {
  async getPayment (paymentId: string): Promise<Payment> {
    const data = await this.get(`/api/v1/payments/${paymentId}`)
    return PaymentSchema.parse(data)
  }

  async createBookingPayment (bookingId: string, paymentMethodId: string, type: string): Promise<PaymentIntent> {
    const response = await this.post(`/api/v1/bookings/${bookingId}/payments`, {
      type,
      payment_method_id: paymentMethodId
    })
    return PaymentIntentSchema.parse(response)
  }

  async createPayment (paymentId: string, paymentMethodId: string): Promise<PaymentIntent> {
    const response = await this.post(`/api/v1/payments/${paymentId}`, {
      payment_method_id: paymentMethodId
    })
    return PaymentIntentSchema.parse(response)
  }

  static get showNorthAmericaDisclaimer (): boolean {
    return import.meta.env.VITE_SHOW_NORTH_AMERICA_DISCLAIMER === 'true'
  }
}
