import Header from '../components/header'
import { Outlet, ScrollRestoration, useSearchParams } from 'react-router-dom'
import NavigationProvider from '../contexts/navigation-context'
import { useEffect } from 'react'
import PageSize from '../components/page-size'
import { useSyncFrameSize } from '../hooks/frame-size'
import BrandProvider, { Brand } from '../contexts/brand'
import TrengoLoader from '../components/trengo-loader'

export default function Page ({ brand = 'boatbiketours' }: { brand?: Brand }): JSX.Element {
  useSyncFrameSize()
  const isDev = import.meta.env.DEV

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const cid = searchParams.get('cid')
    if (cid !== null) {
      localStorage.setItem('cid', cid)
    }
  }, [searchParams])

  return (
    <BrandProvider brand={brand}>
      <div className='grid' data-brand={brand}>
        <div className='flex flex-col accent-brand-primary brand-inselhuepfen:font-open-sans'>
          <NavigationProvider>
            <ScrollRestoration />
            <Header />
            <main className='mb-16 lg:mb-8 frame:lg:mb-0'>
              <Outlet />
            </main>
            {isDev && <PageSize />}
            <TrengoLoader />
          </NavigationProvider>
        </div>
      </div>
    </BrandProvider>
  )
}
